import React from 'react'
import './SearchPage.css'
import { useStateValue } from './StateProvider'
import useGoogleSearch from './useGoogleSearch';
import Response from "./response";
import { Link } from 'react-router-dom';
import Search from "./pages/Search";
import SearchIcon from "@material-ui/icons/Search";
import DescriptionIcon from '@material-ui/icons/Description';
import ImageIcon from '@material-ui/icons/Image';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import RoomIcon from '@material-ui/icons/Room';
import MoreVertIcon from '@material-ui/icons/MoreVert';



function SearchPage() {
    const [{ term }, dispatch]= useStateValue();
    
    // LIVE API CALL 
    const { data } =useGoogleSearch(term);   

    //  MOCK API CALL
    // const data = Response;

    console.log(data);
  return (
    <div className='searchpage'>
        <div className='searchPage__header'>
          <Link to="/">
                    <img
                        className="searchPage__logo"
                        src="https://f004.backblazeb2.com/file/softbase/fuzokusearch_logo.PNG"
                        alt=""
                    />
          </Link>
          
          <div className='searchPage__headerBody'>
            <Search hideButtons />

            <div className='searchPage__options'>
                 <div className="searchPage__optionsLeft">

                            <div className="searchPage__option">
                                <SearchIcon />
                                <Link to="">All</Link>
                            </div>

                            {/* <div className="searchPage__option">
                                <DescriptionIcon />
                                <Link to="">News</Link>
                            </div> */}

                            <div className="searchPage__option">
                                <ImageIcon />
                                <Link to="">Images</Link>
                            </div>

                            {/* <div className="searchPage__option">
                                <LocalOfferIcon />
                                <Link to="">Shopping</Link>
                            </div> */}

                            <div className="searchPage__option">
                                <RoomIcon />
                                <Link to="">Maps</Link>
                            </div>

                            <div className="searchPage__option">
                                <MoreVertIcon />
                                <Link to="">more</Link>
                            </div> 
                          </div>     

                         <div className="searchPage__optionsRight">

                            <div className="searchPage__option">
                                <Link to="">Settings</Link>
                            </div>

                            <div className="searchPage__option">
                                <Link to="">Tools</Link>
                            </div>

                        </div>     
                    </div>
          </div>
        </div>

        
        
        {term && (
                <div className="searchPage__results">
                    <p
                    className="searchPage__resultCount">
                        About {data?.searchInformation.formattedTotalResults} 
                        ({data?.searchInformation.formattedSearchTime} seconds) for 
                         {data?.queries.nextPage[0].searchTerms}
                    </p>

                    {data?.items.map(item => (
                      <div className="searchPage__result">
                          <a
                            href={item.link}
                          >
                            {item.pagemap?.cse_image?.length > 0 && item.pagemap?.cse_image[0]?.src && (
                                <img 
                                    className="searchPage__resultImage"
                                 src = {item.pagemap?.cse_image[0]?.src}
                                />
                            )} 
                            {item.displayLink} &#x25BC;
                          </a>

                          <a className="searchPage__resultTitle" href={item.link}>
                              <h2>{item.title}</h2>
                          </a>

                          <p className="searchPage__resultSnippet">{item.snippet}</p>
                          
                      </div>  
                    ))}
                </div>
            )}
    
    
    </div>
  )
}

export default SearchPage